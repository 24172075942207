<template>
  <div class="editorParent">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="editor-menubar">
        <span
          class="menubar-button icon-button"
          :class="{ 'is-active': showEditorMenuBar }"
          @click="showEditorMenuBar = !showEditorMenuBar"
        >
          <font-awesome-icon v-if="showEditorMenuBar" icon="chevron-right" />
          <font-awesome-icon v-if="!showEditorMenuBar" icon="chevron-down" />
        </span>
        <div style="display: inline" v-if="showEditorMenuBar">
          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <font-awesome-icon icon="bold" />
          </span>

          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <font-awesome-icon icon="italic" />
          </span>

          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.strike() }"
            @click="commands.strike"
          >
            <font-awesome-icon icon="strikethrough" />
          </span>

          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <font-awesome-icon icon="underline" />
          </span>

          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.code() }"
            @click="commands.code"
          >
            <font-awesome-icon icon="code" />
          </span>

          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <font-awesome-icon icon="paragraph" />
          </span>

          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
          >
            H1
          </span>

          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            H2
          </span>

          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            H3
          </span>

          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <font-awesome-icon icon="list-ul" />
          </span>

          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <font-awesome-icon icon="list-ol" />
          </span>

          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.blockquote() }"
            @click="commands.blockquote"
          >
            <font-awesome-icon icon="quote-right" />
          </span>

          <span
            class="menubar-button icon-button"
            :class="{ 'is-active': isActive.code_block() }"
            @click="commands.code_block"
          >
            <font-awesome-icon icon="code" />
          </span>

          <span
            class="menubar-button icon-button"
            @click="commands.horizontal_rule"
          >
            <font-awesome-icon icon="ruler-horizontal" />
          </span>

          <span class="menubar-button icon-button" @click="commands.undo">
            <font-awesome-icon icon="undo-alt" />
          </span>

          <span class="menubar-button icon-button" @click="commands.redo">
            <font-awesome-icon icon="redo-alt" />
          </span>
        </div>
      </div>
    </editor-menu-bar>
    <div class="editorContainer">
      <editor-content
        class="editor-content"
        id="editor-content"
        :editor="editor"
        @click="focus()"
      />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from "tiptap-extensions";

export default {
  name: "Editor",
  components: {
    EditorContent,
    EditorMenuBar,
  },
  data() {
    return {
      editor: null,
      showEditorMenuBar: false,
      fontFamily: "Arial, Helvetica, sans-serif",
      fontSize: "11pt",
    };
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
      ],
      autoFocus: true,
    });

    // set fontFamily and fontSize
    var editorContent = document.getElementById("editor-content");
    if (editorContent) {
      editorContent.style.fontFamily = this.fontFamily;
      editorContent.style.fontSize = this.fontSize;
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    getHtmlInput() {
      return this.editor.getHTML();
    },
    setHtmlInput(htmlText) {
      // no text return
      if (!htmlText) {
        console.error("setHtmlInput no htmlText");
        return;
      }

      var proseMirror = null;
      var proseMirrors = document.getElementsByClassName("ProseMirror");
      if (proseMirrors && proseMirrors.length == 1) {
        proseMirror = proseMirrors[0];
      }

      if (proseMirror) {
        proseMirror.innerHTML = htmlText;
      } else {
        console.error("setHtmlInput proseMirror could not be found");
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.editorParent {
  // padding: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.editor-menubar {
  display: inline-block;
  background: #fff;
  padding: 0.125em;

  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

  overflow-y: auto;
  scrollbar-width: thin;

  flex-shrink: 0;

  // white-space: pre; // uncomment to use scrolling editor buttons - does not work correctly while resizing the window
}

.menubar-button {
  display: inline-flex;
  border: 0;
  color: #333;
  background: #e4e4e4;
  border-radius: 1em;
  cursor: pointer;
  margin: 0.125em;
  vertical-align: middle;

  &:hover {
    background: #d4d4d4;
    color: #333;
  }

  &.is-active {
    background: #333;
    color: #d4d4d4;
  }
}

.editorContainer {
  background: #fff;
  padding: 0 1em;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
  flex-grow: 1;
  overflow: auto;
  cursor: text;
  display: flex;
  flex-direction: column;
  margin: 0.5em;
  border-radius: 0.5em;
}
</style>

<style lang="scss">
.ProseMirror:focus {
  outline: none;
}

.ProseMirror {
  flex-grow: 1;
  padding: 1em 0;
}

.ProseMirror p {
  margin: 0;
}

.editor-container {
  background: #faa;
}
</style>